<template>
  <div>
    <h3 class="mb-1">
      Видео-обзоры
    </h3>
    <b-row>
      <b-col
        v-for="(video, key) in videos"
        :key="`video-${key}`"
        cols="12"
        md="6"
        lg="4"
        class="mb-2"
      >
        <help-video-card
          :video-id="video.id"
          :title="video.title"
        />
      </b-col>
    </b-row>
    <b-row>
      <!--      <b-col-->
      <!--        cols="6"-->
      <!--        md="3"-->
      <!--      >-->
      <!--        <b-button-->
      <!--          block-->
      <!--          variant="primary"-->
      <!--          :to="{name: 'cabinet.instruction'}"-->
      <!--        >-->
      <!--          <span>Хочу получать заявки</span>-->
      <!--        </b-button>-->
      <!--      </b-col>-->
      <b-col
        cols="12"
        md="3"
      >
        <b-button
          variant="primary"
          block
          :to="{name: 'cabinet.chat'}"
        >
          <feather-icon
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Связаться с поддержкой</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import HelpVideoCard from '../components/help/HelpVideoCard.vue'

export default {
  name: 'Help',
  components: {
    HelpVideoCard,
    BRow,
    BButton,
    BCol,
  },
  data: () => ({
    videos: [
      {
        title: 'Как добавить ученика?',
        id: '6W6o3DfNuRc',
      },
      {
        title: 'Создание урока',
        id: 'GSeyKPLXIJo',
      },
      {
        title: 'Как начать урок?',
        id: '_EQikMJ5RJs',
      },
    ],
  }),

}
</script>

<style scoped>

</style>
