<template>
  <b-card
    class="overflow-hidden full-height mb-0"
    no-body
  >
    <b-aspect aspect="16:9">
      <youtube
        ref="video"
        :video-id="videoId"
        width="100%"
        height="100%"
      />
    </b-aspect>

    <b-card-body class="d-flex flex-column justify-content-between">
      <b-card-title class="mb-0">
        {{ title }}
      </b-card-title>
      <div class="mt-1 d-flex justify-content-end">
        <b-button
          size="sm"
          variant="flat-primary"
          @click="playVideo()"
        >
          <feather-icon
            :icon="isPlaying ? 'PauseIcon' : 'PlayIcon'"
            class="mr-50"
          />
          {{ isPlaying ? 'Приостановить' : 'Посмотреть' }}
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BButton, BCardTitle, BAspect,
} from 'bootstrap-vue'

export default {
  name: 'HelpVideoCard',
  components: {
    BCard, BCardBody, BButton, BCardTitle, BAspect,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    videoId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isPlaying: false,
  }),
  computed: {
    player() {
      return this.$refs.video.player
    },
  },
  methods: {
    async playVideo() {
      const { player } = this
      const state = await player.getPlayerState()
      if (state === 1) {
        player.pauseVideo()
        this.isPlaying = false
      } else {
        player.playVideo()
        this.isPlaying = true
      }
    },
  },
}
</script>

<style scoped>

</style>
